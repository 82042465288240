module.exports = [{
      plugin: require('/zeit/309790ce/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"sam-and-su-tsai","short_name":"sstsai","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('/zeit/309790ce/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/309790ce/node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/309790ce/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
