export default {
  useCustomProperties: true,
  initialColorMode: "light",
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
      textDecoration: "none",
      ":hover": {
        color: "secondary",
        textDecoration: "underline",
      },
    },
  },
  colors: {
    text: "#333",
    background: "#fff",
    primary: "#639",
    secondary: "#07c",
  },
  fonts: {
    body: '"Source Sans Pro", sans-serif',
    heading: '"Source Sans Pro", sans-serif',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  buttons: {
    primary: {
      color: "background",
      bg: "primary",
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
    gray: {
      color: "background",
      bg: "gray",
    },
  },
}
